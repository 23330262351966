import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "@/views/MailsView.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: {
      auth: true,
    },
  },
  {
    path: "/mail/send",
    name: "MailSend",
    component: () => import("@/views/MailSendView.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/mail/send2",
    name: "MailSend2",
    component: () => import("@/views/MailSendView2.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/views",
    name: "Views",
    component: () => import("@/views/ViewsView.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: () => import("@/views/TasksView.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/NotFoundView.vue"),
    meta: {
      auth: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
